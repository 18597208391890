@tailwind base;
@tailwind components;
@tailwind utilities;

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Hide scrollbar */
.scroll-div::-webkit-scrollbar {
  width: 0.25rem;
}

.scroll-div::-webkit-scrollbar-track {
  background-color: transparent;
}

.scroll-div::-webkit-scrollbar-thumb {
  background-color: rgba(0,0,0,0.2);
}

/* Allow scrolling */
.scroll-div {
  overflow-y: scroll;
}

